import { render, staticRenderFns } from "./ICSection.vue?vue&type=template&id=a9f0dc92&"
import script from "./ICSection.vue?vue&type=script&lang=ts&"
export * from "./ICSection.vue?vue&type=script&lang=ts&"
import style0 from "./ICSection.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports