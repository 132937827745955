var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"plan-editor-modal",attrs:{"maskClosable":false,"visible":_vm.visible,"title":_vm.$t(("smartCurriculum." + (_vm.type || 'add') + "ClassMixedCourse")),"destroyOnClose":"","center":"","width":900},on:{"cancel":_vm.beforeClose}},[_c('a-form-model',{ref:"planForm",staticClass:"plan-form",attrs:{"model":_vm.planForm,"selfUpdate":true}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('student.class'),"rules":[
        {
          required: true,
          message: _vm.$t('common.select'),
        } ],"prop":"classes"}},[_c('a-select',{attrs:{"showSearch":"","mode":"multiple","optionFilterProp":"children","placeholder":_vm.$t('common.select')},model:{value:(_vm.planForm.classes),callback:function ($$v) {_vm.$set(_vm.planForm, "classes", $$v)},expression:"planForm.classes"}},_vm._l((_vm.classes),function(sClass){return _c('a-select-option',{key:sClass.key,attrs:{"disabled":((_vm.planForm.classes || [])[0] &&
              sClass.gradeId !==
                _vm.classes.find(function (item) { return item.key === _vm.planForm.classes[0]; }).gradeId) ||
              _vm.selectedClass.includes(sClass.key)}},[_vm._v(_vm._s(sClass.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('courseModule.course'),"rules":[
        {
          required: true,
          message: _vm.$t('common.select'),
        } ],"prop":"courseId"}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('common.select')},model:{value:(_vm.planForm.courseId),callback:function ($$v) {_vm.$set(_vm.planForm, "courseId", $$v)},expression:"planForm.courseId"}},_vm._l((_vm.availableCourses),function(course){return _c('a-select-option',{key:course.key},[_vm._v(_vm._s(course.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.level'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        } ],"prop":"level"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":5,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"blur":_vm.onLevelBlur},model:{value:(_vm.planForm.level),callback:function ($$v) {_vm.$set(_vm.planForm, "level", $$v)},expression:"planForm.level"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.weeklyClass'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.validateWeek,
        },
        {
          validator: _vm.validateWeek,
        } ],"prop":"hoursByWeek"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":50,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours', 'continuousTimes'])}},model:{value:(_vm.planForm.hoursByWeek),callback:function ($$v) {_vm.$set(_vm.planForm, "hoursByWeek", $$v)},expression:"planForm.hoursByWeek"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.consequentPeriods'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.validatePeriods,
          with: 'hoursByWeek',
        },
        {
          validator: _vm.validatePeriods,
          with: 'maxHoursByDay',
        } ],"prop":"continuousHours"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":50,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours', 'hoursByWeek', 'maxHoursByDay'])}},model:{value:(_vm.planForm.continuousHours),callback:function ($$v) {_vm.$set(_vm.planForm, "continuousHours", $$v)},expression:"planForm.continuousHours"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.consequentTimes'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.validateTimes,
        } ],"prop":"continuousTimes"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":0,"max":50,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours', 'hoursByWeek'])}},model:{value:(_vm.planForm.continuousTimes),callback:function ($$v) {_vm.$set(_vm.planForm, "continuousTimes", $$v)},expression:"planForm.continuousTimes"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.maxClasses'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.valiedateMaxClasses,
        } ],"prop":"maxHoursByDay"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":50,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours'])}},model:{value:(_vm.planForm.maxHoursByDay),callback:function ($$v) {_vm.$set(_vm.planForm, "maxHoursByDay", $$v)},expression:"planForm.maxHoursByDay"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.priorityTime'),"prop":"apm"}},[_c('a-select',{attrs:{"optionFilterProp":"children","placeholder":_vm.$t('common.select'),"allowClear":""},model:{value:(_vm.planForm.apm),callback:function ($$v) {_vm.$set(_vm.planForm, "apm", $$v)},expression:"planForm.apm"}},_vm._l((_vm.periods),function(period){return _c('a-select-option',{key:period.key},[_vm._v(_vm._s(period.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.priorityScheduling'),"prop":"priority","rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        } ]}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":100,"precision":0,"placeholder":_vm.$t('common.enter')},model:{value:(_vm.planForm.priority),callback:function ($$v) {_vm.$set(_vm.planForm, "priority", $$v)},expression:"planForm.priority"}}),_c('a-tooltip',{attrs:{"title":_vm.$t('smartCurriculum.priorityTips')}},[_c('a-icon',{staticStyle:{"vertical-align":"middle","margin-left":"4px"},attrs:{"type":"question-circle"}})],1)],1),_vm._l((_vm.levels),function(level,index){return [_c('div',{key:("title" + index),staticClass:"common-title"},[_c('div',{staticClass:"title-bar"}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('smartCurriculum.levelLabel', { num: _vm.romanNum[index] })))])]),_c('a-form-model-item',{key:("name" + index),attrs:{"label":_vm.$t('smartCurriculum.sectionName'),"prop":("level" + index + ".name"),"rules":[
          {
            required: true,
            message: _vm.$t('common.enter'),
          },
          {
            max: 50,
            message: _vm.$t('tips.textLimit', { num: 50 }),
          },
          {
            validator: _vm.validateName,
            index: index,
          },
          {
            whitespace: true,
            message: _vm.$t('common.enter'),
          } ]}},[_c('a-input',{attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(_vm.planForm[("level" + index)].name),callback:function ($$v) {_vm.$set(_vm.planForm[("level" + index)], "name", $$v)},expression:"planForm[`level${index}`].name"}})],1),_c('a-form-model-item',{key:("teacher" + index),attrs:{"label":_vm.$t('common.teacher'),"required":true,"help":_vm.getTeacherError(index) ? _vm.$t('course.selectTeacher') : '',"validateStatus":_vm.getTeacherError(index) ? 'error' : ''}},[_c('a-form-model-item',{attrs:{"colon":false,"label":''}},[_c('a-radio-group',{on:{"change":function($event){return _vm.onTypeChange(("level" + index))}},model:{value:(_vm.planForm[("level" + index)].teacherType),callback:function ($$v) {_vm.$set(_vm.planForm[("level" + index)], "teacherType", $$v)},expression:"planForm[`level${index}`].teacherType"}},[_c('a-radio',{attrs:{"value":"teacher"}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.selectTeacher')))]),_c('a-radio',{attrs:{"value":"teacherGroup"}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.selectTeacherGroup')))])],1)],1),_c('a-form-model-item',{staticClass:"form-item-no-margin",attrs:{"colon":false,"label":''}},[_c('a-select',{attrs:{"showSearch":"","mode":_vm.planForm[("level" + index)].teacherType === 'teacher' ? 'multiple' : 'default',"optionFilterProp":"children","placeholder":_vm.$t('common.select')},on:{"change":function($event){return _vm.onTeacherChange(("level" + index))}},model:{value:(_vm.selectedTeacher[("level" + index)]),callback:function ($$v) {_vm.$set(_vm.selectedTeacher, ("level" + index), $$v)},expression:"selectedTeacher[`level${index}`]"}},_vm._l((_vm.planForm[("level" + index)].teacherType === 'teacher'
                ? _vm.teachers
                : _vm.teacherGroups),function(teacher){return _c('a-select-option',{key:teacher.key,attrs:{"disabled":_vm.allSelectedTeacherId.includes(teacher.key) &&
                  _vm.planForm[("level" + index)].teacherType === 'teacher' &&
                  !(_vm.selectedTeacher[("level" + index)] || []).includes(teacher.key)}},[_vm._v(_vm._s(teacher.value))])}),1)],1)],1),_c('a-form-model-item',{key:("classroom" + index),attrs:{"label":_vm.$t('course.classroom'),"prop":("level" + index + ".classroom")}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('tips.selectClassroom'),"allowClear":""},model:{value:(_vm.planForm[("level" + index)].classroom),callback:function ($$v) {_vm.$set(_vm.planForm[("level" + index)], "classroom", $$v)},expression:"planForm[`level${index}`].classroom"}},_vm._l((_vm.classrooms),function(classroom){return _c('a-select-option',{key:classroom.key,attrs:{"disabled":_vm.usedRoom
                .filter(function (item) { return item !== _vm.planForm[("level" + index)].classroom; })
                .includes(classroom.key)}},[_vm._v(_vm._s(_vm.locale === 'zh' ? classroom.value : classroom.enValue || classroom.value))])}),1)],1)]})],2),_c('transfer',{attrs:{"title":_vm.$t('course.addTeacher'),"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.transferVis),callback:function ($$v) {_vm.transferVis=$$v},expression:"transferVis"}}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.savePlan()}}},[_vm._v(_vm._s(_vm.$t('common.save')))]),(_vm.type === 'add')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.savePlan(true)}}},[_vm._v(_vm._s(_vm.$t('CCAClass.saveAndAdd')))]):_vm._e(),_c('a-button',{on:{"click":_vm.beforeClose}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }