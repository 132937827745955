var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"maskClosable":false,"visible":_vm.visible,"title":_vm.$t('router.addCourseClass'),"destroyOnClose":"","center":"","width":900,"confirmLoading":_vm.loading},on:{"cancel":_vm.closeModal,"ok":_vm.saveCourse}},[_c('a-form-model',{ref:"courseEditor",staticClass:"section-form",attrs:{"model":_vm.courseEditor,"rules":_vm.rules}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.course'),"prop":"course","required":""}},[_c('a-cascader',{attrs:{"options":_vm.courses,"fieldNames":{ label: 'value', value: 'key', children: 'subOptions' },"placeholder":_vm.$t('common.select')},on:{"change":_vm.changeCourse},model:{value:(_vm.courseEditor.course),callback:function ($$v) {_vm.$set(_vm.courseEditor, "course", $$v)},expression:"courseEditor.course"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.sectionNum'),"prop":"sectionNum","required":""}},[_c('a-input-number',{attrs:{"min":1},on:{"change":_vm.changeSectionNum},model:{value:(_vm.courseEditor.sectionNum),callback:function ($$v) {_vm.$set(_vm.courseEditor, "sectionNum", $$v)},expression:"courseEditor.sectionNum"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.weeklyClass'),"prop":"periodsByWeek","required":""}},[_c('a-input-number',{attrs:{"min":1},model:{value:(_vm.courseEditor.periodsByWeek),callback:function ($$v) {_vm.$set(_vm.courseEditor, "periodsByWeek", $$v)},expression:"courseEditor.periodsByWeek"}})],1)],1),_vm._l((_vm.courseForms),function(course,index){return [_c('a-form-model',{key:("form-" + (course.id)),ref:("course-" + index),refInFor:true,attrs:{"model":_vm.courseForms[index]}},[_c('div',{key:course.id,staticClass:"title-text"},[_vm._v(_vm._s(("Section " + (index + 1))))]),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.semester'),"prop":"semesters","rules":[
          {
            required: true,
            message: _vm.$t('common.select'),
          },
          {
            validator: _vm.validateSemester,
          } ],"required":""}},[_c('a-select',{attrs:{"placeholder":_vm.$t('common.select'),"mode":"multiple"},model:{value:(_vm.courseForms[index].semesters),callback:function ($$v) {_vm.$set(_vm.courseForms[index], "semesters", $$v)},expression:"courseForms[index].semesters"}},_vm._l((_vm.semesters),function(item){return _c('a-select-option',{key:item.key,attrs:{"disabled":_vm.semesterDisabled(index, item)}},[_vm._v(_vm._s(item.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.sectionName'),"prop":"name","rules":[
          {
            required: true,
            message: _vm.$t('common.enter'),
          },
          {
            max: 50,
            message: _vm.$t('tips.textLimit', { num: 50 }),
          } ]}},[_c('a-input',{attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(_vm.courseForms[index].name),callback:function ($$v) {_vm.$set(_vm.courseForms[index], "name", $$v)},expression:"courseForms[index].name"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('course.classroom'),"prop":"classroom","rules":[
          {
            required: true,
            message: _vm.$t('tips.selectClassroom'),
          } ]}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('tips.selectClassroom')},model:{value:(_vm.courseForms[index].classroom),callback:function ($$v) {_vm.$set(_vm.courseForms[index], "classroom", $$v)},expression:"courseForms[index].classroom"}},_vm._l((_vm.classrooms),function(classroom){return _c('a-select-option',{key:classroom.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? classroom.value : classroom.enValue || classroom.value))])}),1)],1),_c('a-form-model-item',{ref:("course-" + index + "-teachers"),refInFor:true,attrs:{"label":_vm.$t('common.teacher'),"prop":"teachers","autoLink":false,"rules":[
          {
            required: true,
            message: _vm.$t('course.selectTeacher'),
          } ],"required":""}},[_c('a-radio-group',{staticStyle:{"margin-bottom":"4px"},attrs:{"value":_vm.courseForms[index].teacherType},on:{"change":function (e) { return _vm.changeTeacherType(course, e); }}},[_c('a-radio',{attrs:{"value":"code"}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.teacherCode')))]),_c('a-radio',{attrs:{"value":"teacher"}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.staff')))])],1),_c('a-select',{attrs:{"mode":"multiple","placeholder":_vm.$t('course.selectTeacher'),"option-filter-prop":"children"},on:{"blur":function () { return _vm.onFieldBlur(index); },"change":function () { return _vm.onFieldChange(index); }},model:{value:(_vm.courseForms[index].teachers),callback:function ($$v) {_vm.$set(_vm.courseForms[index], "teachers", $$v)},expression:"courseForms[index].teachers"}},[(_vm.courseForms[index].teacherType === 'code')?_vm._l((_vm.teacherCodes),function(teacher){return _c('a-select-option',{key:teacher.key},[_vm._v(_vm._s(teacher.value))])}):_vm._e(),(_vm.courseForms[index].teacherType === 'teacher')?_vm._l((_vm.teachers),function(teacher){return _c('a-select-option',{key:teacher.teacherId},[_vm._v(_vm._s((teacher.enName + ' ' + teacher.name).trim()))])}):_vm._e()],2)],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }