var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"i-teachers"},[_c('div',{staticClass:"button-container"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.add')))])])],1),_c('a-table',{staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":false,"rowKey":function (record, index) { return record.teacherMutexId; }},scopedSlots:_vm._u([{key:"commonFlex",fn:function(text){return [_c('flex-tooltip',{attrs:{"value":text}})]}},{key:"teacher",fn:function(text){return [_c('flex-tooltip',{attrs:{"value":text.value}})]}},{key:"teachers",fn:function(text){return [_c('flex-tooltip',{attrs:{"value":text.map(function (item) { return item.value; }).join('、')}})]}},{key:"operations",fn:function(record){return [_c('a',{on:{"click":function($event){return _vm.edit(record)}}},[_vm._v(_vm._s(_vm.$t('common.edit')))]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"title":_vm.$t('smartCurriculum.planDeleteConfirm')},on:{"confirm":function($event){return _vm.deleteRecord(record.teacherMutexId)}}},[_c('a',[_vm._v(_vm._s(_vm.$t('common.delete')))])])]}}])}),_c('a-modal',{attrs:{"visible":_vm.modalVis,"title":_vm.$t('smartCurriculum.incompatibleTeacher', { prefix: _vm.$t(("common." + _vm.editType)) }),"width":600,"okText":_vm.$t('common.save'),"maskClosable":false,"destroyOnClose":""},on:{"cancel":_vm.beforeClose}},[_c('a-form-model',{ref:"teacherForm",staticClass:"teacher-form",attrs:{"model":_vm.teacherForm}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('common.teacher'),"rules":[
          {
            required: true,
            message: _vm.$t('common.select'),
          } ],"prop":"teacherId"}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('common.select'),"disabled":_vm.editType === 'edit'},on:{"change":_vm.onTeacherChange},model:{value:(_vm.teacherForm.teacherId),callback:function ($$v) {_vm.$set(_vm.teacherForm, "teacherId", $$v)},expression:"teacherForm.teacherId"}},_vm._l((_vm.teachers),function(teacher){return _c('a-select-option',{key:teacher.key},[_vm._v(_vm._s(teacher.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.incompatibleTeacher', { prefix: '' }),"rules":[
          {
            required: true,
            message: _vm.$t('common.select'),
          } ],"prop":"mutexTeachers"}},[_c('a-select',{attrs:{"showSearch":"","mode":"multiple","optionFilterProp":"children","placeholder":_vm.$t('common.select')},model:{value:(_vm.teacherForm.mutexTeachers),callback:function ($$v) {_vm.$set(_vm.teacherForm, "mutexTeachers", $$v)},expression:"teacherForm.mutexTeachers"}},_vm._l((_vm.teachers),function(teacher){return _c('a-select-option',{key:teacher.key,attrs:{"disabled":teacher.key === _vm.teacherForm.teacherId || _vm.disabledTeachers.includes(teacher.key)}},[_vm._v(_vm._s(teacher.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.leastInTime'),"rules":[
          {
            required: true,
            message: _vm.$t('common.enter'),
          } ],"prop":"leastTime"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"min":1,"max":50,"precision":0,"placeholder":_vm.$t('common.enter')},model:{value:(_vm.teacherForm.leastTime),callback:function ($$v) {_vm.$set(_vm.teacherForm, "leastTime", $$v)},expression:"teacherForm.leastTime"}})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveTeacher()}}},[_vm._v(_vm._s(_vm.$t('common.save')))]),(_vm.editType === 'add')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveTeacher(true)}}},[_vm._v(_vm._s(_vm.$t('CCAClass.saveAndAdd')))]):_vm._e(),_c('a-button',{on:{"click":_vm.beforeClose}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }