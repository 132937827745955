var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"curriculum-table"},[_c('div',{staticClass:"curriculum-table-body"},[_c('div',{staticClass:"period-column"},[_c('div',{staticClass:"empty-block"}),_vm._l((_vm.periods),function(period){return _c('div',{key:period.classPeriodId,staticClass:"period-item"},[_c('span',{staticClass:"ellipsis",attrs:{"title":period.description}},[_vm._v(_vm._s(period.description))])])})],2),_c('div',{staticClass:"curriculum-class-block"},_vm._l((_vm.week),function(day){return _c('div',{key:day.dayOfWeek,staticClass:"curriculum-column"},[_c('div',{staticClass:"day-container"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.locale === 'zh' ? day.name : day.enName))])]),_vm._l((_vm.periods),function(period){return _c('a-dropdown',{key:period.classPeriodId,attrs:{"trigger":['contextmenu']}},[_c('div',{staticClass:"course-container",style:({ backgroundColor: _vm.calcBg(day[period.classPeriodId]) })},[(day[period.classPeriodId])?[(day[period.classPeriodId].length)?_c('div',{staticClass:"side-bar",style:({
                  backgroundColor: day[period.classPeriodId][day[period.classPeriodId].length - 1]
                    .subject
                    ? day[period.classPeriodId][day[period.classPeriodId].length - 1].subject
                        .color
                    : 'transparent',
                })}):_vm._e(),_c('div',{staticClass:"course-content"},[(day[period.classPeriodId].length)?[_c('a-tooltip',{attrs:{"title":day[period.classPeriodId][day[period.classPeriodId].length - 1].description}},[(
                        day[period.classPeriodId][day[period.classPeriodId].length - 1]
                          .description
                      )?_c('span',[_vm._v(_vm._s(day[period.classPeriodId][day[period.classPeriodId].length - 1] .description))]):_vm._e()])]:_vm._e()],2)]:_vm._e()],2),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[(day[period.classPeriodId] && day[period.classPeriodId].length)?_c('a-menu-item',{on:{"click":function($event){return _vm.removeCourse(period.classPeriodId, day.dayOfWeek)}}},[_vm._v(_vm._s(_vm.$t('course.removeCourse')))]):_c('a-menu-item',{on:{"click":function($event){return _vm.addSingleCourse(period.classPeriodId, day.dayOfWeek)}}},[_vm._v(_vm._s(_vm.$t('courseModule.addCourse')))])],1)],1)})],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }