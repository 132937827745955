import { render, staticRenderFns } from "./DraftEditor.vue?vue&type=template&id=c42d1ac2&scoped=true&"
import script from "./DraftEditor.vue?vue&type=script&lang=ts&"
export * from "./DraftEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c42d1ac2",
  null
  
)

export default component.exports