var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"section-editor-modal",attrs:{"maskClosable":false,"visible":_vm.visible,"title":_vm.$t('smartCurriculum.editSection'),"destroyOnClose":"","center":"","width":900},on:{"cancel":_vm.beforeClose,"ok":_vm.saveSection}},[_c('a-form-model',{ref:"sectionForm",staticClass:"section-form",attrs:{"model":_vm.sectionForm}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('common.class')}},[_c('a-input',{attrs:{"value":(_vm.sectionForm.classes || []).map(function (item) { return item.value; }).join('、'),"disabled":""}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('courseModule.course')}},[_c('a-input',{attrs:{"value":(_vm.sectionForm.course || {}).value,"disabled":""}})],1),(_vm.sectionForm.isSplit)?_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.level')}},[_c('a-input',{attrs:{"value":_vm.sectionForm.level,"disabled":""}})],1):_vm._e(),_vm._l((_vm.levels),function(level,index){return [(_vm.levels >= 2)?_c('div',{key:("title" + index),staticClass:"common-title"},[_c('div',{staticClass:"title-bar"}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('smartCurriculum.levelLabel', { num: _vm.romanNum[index] })))])]):_vm._e(),_c('a-form-model-item',{key:("name" + index),attrs:{"label":_vm.$t('smartCurriculum.sectionName'),"prop":("level" + index + ".name"),"rules":[
          {
            required: true,
            message: _vm.$t('common.enter'),
          },
          {
            max: 50,
            message: _vm.$t('tips.textLimit', { num: 50 }),
          } ]}},[_c('a-input',{attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(_vm.sectionForm[("level" + index)].name),callback:function ($$v) {_vm.$set(_vm.sectionForm[("level" + index)], "name", $$v)},expression:"sectionForm[`level${index}`].name"}})],1),_c('a-form-model-item',{key:("teacher" + index),attrs:{"label":_vm.$t('common.teacher'),"required":true,"help":_vm.getTeacherError(index) ? _vm.$t('course.selectTeacher') : '',"validateStatus":_vm.getTeacherError(index) ? 'error' : ''}},[_c('a-select',{attrs:{"showSearch":"","mode":"multiple","optionFilterProp":"children","placeholder":_vm.$t('common.select')},on:{"change":function($event){return _vm.onTeacherChange(("level" + index))}},model:{value:(_vm.selectedTeacher[("level" + index)]),callback:function ($$v) {_vm.$set(_vm.selectedTeacher, ("level" + index), $$v)},expression:"selectedTeacher[`level${index}`]"}},_vm._l((_vm.teachers),function(teacher){return _c('a-select-option',{key:teacher.key,attrs:{"disabled":_vm.allSelectedTeacherId.includes(teacher.key) &&
                !(_vm.selectedTeacher[("level" + index)] || []).includes(teacher.key)}},[_vm._v(_vm._s(teacher.value))])}),1)],1),_c('a-form-model-item',{key:("classroom" + index),attrs:{"label":_vm.$t('course.classroom'),"prop":("level" + index + ".classroom")}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('tips.selectClassroom')},model:{value:(_vm.sectionForm[("level" + index)].classroom),callback:function ($$v) {_vm.$set(_vm.sectionForm[("level" + index)], "classroom", $$v)},expression:"sectionForm[`level${index}`].classroom"}},_vm._l((_vm.classrooms),function(classroom){return _c('a-select-option',{key:classroom.key,attrs:{"disabled":_vm.usedRoom
                .filter(function (item) { return item !== _vm.sectionForm[("level" + index)].classroom; })
                .includes(classroom.key)}},[_vm._v(_vm._s(_vm.locale === 'zh' ? classroom.value : classroom.enValue || classroom.value))])}),1)],1)]})],2),_c('transfer',{attrs:{"title":_vm.$t('course.addTeacher'),"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.transferVis),callback:function ($$v) {_vm.transferVis=$$v},expression:"transferVis"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }