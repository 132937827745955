var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-editor"},[_c('a-form',{staticStyle:{"margin-bottom":"16px"},attrs:{"form":_vm.periodEditor,"layout":"inline"}},[_c('a-form-item',{attrs:{"label":_vm.$t('period.appliedSection')}},[(_vm.type === 'view')?_c('span',[_vm._v(_vm._s(_vm.data.sections))]):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('period.enterPeriodName'),
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('period.enterPeriodName'),\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('period.enterPeriodName')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('period.schoolYear')}},[(_vm.type === 'view')?[(_vm.data.schoolYear)?_c('span',[_vm._v(_vm._s(_vm.data.schoolYear.name))]):_vm._e()]:_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'sectionIds',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('period.selectSection'),
              } ],
          } ]),expression:"[\n          'sectionIds',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('period.selectSection'),\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"200px"},attrs:{"mode":"multiple","showArrow":"","optionFilterProp":"children","maxTagCount":1,"placeholder":_vm.$t('period.selectSection')}},_vm._l((_vm.sections),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(item.value))])}),1)],2)],1),_c('table',{staticClass:"edit-table"},[_c('tr',{staticClass:"table-header"},[_c('th',[_vm._v(_vm._s(_vm.$t('period.class')))]),_c('th',[_vm._v(_vm._s(_vm.$t('period.time')))]),_c('th',[_vm._v(_vm._s(_vm.$t('weekday.monday')))]),_c('th',[_vm._v(_vm._s(_vm.$t('weekday.tuesday')))]),_c('th',[_vm._v(_vm._s(_vm.$t('weekday.wednesday')))]),_c('th',[_vm._v(_vm._s(_vm.$t('weekday.thursday')))]),_c('th',[_vm._v(_vm._s(_vm.$t('weekday.friday')))])]),_vm._l((_vm.data.table),function(item){return _c('tr',{key:item.classPeriodId},[_c('td',[_vm._v(_vm._s(item.description))]),_c('td',{staticStyle:{"width":"130px"}},[_vm._v(_vm._s(item.time))]),_vm._l((item.arranges),function(it,index){return _c('td',{key:index,attrs:{"colspan":it.col}},[_vm._v(" "+_vm._s(it.content)+" ")])})],2)})],2),(_vm.type !== 'view')?_c('div',{staticClass:"operation-container"},[_c('a-button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c('a-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }