var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"plan-editor-modal",attrs:{"maskClosable":false,"visible":_vm.visible,"title":_vm.$t(("smartCurriculum." + (_vm.type || 'add') + "CoursePlan")),"destroyOnClose":"","center":"","width":900},on:{"cancel":_vm.beforeClose}},[_c('a-form-model',{ref:"planForm",staticClass:"plan-form",attrs:{"model":_vm.planForm}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('student.class'),"rules":[
        {
          required: true,
          message: _vm.$t('common.select'),
        } ],"prop":"classId"}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('common.select'),"disabled":_vm.type === 'edit'},on:{"change":_vm.onClassChange},model:{value:(_vm.planForm.classId),callback:function ($$v) {_vm.$set(_vm.planForm, "classId", $$v)},expression:"planForm.classId"}},_vm._l((_vm.classes),function(sClass){return _c('a-select-option',{key:sClass.key},[_vm._v(_vm._s(sClass.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('courseModule.course'),"rules":[
        {
          required: true,
          message: _vm.$t('common.select'),
        } ],"prop":"courseId"}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('common.select')},on:{"change":_vm.onCourseChange},model:{value:(_vm.planForm.courseId),callback:function ($$v) {_vm.$set(_vm.planForm, "courseId", $$v)},expression:"planForm.courseId"}},_vm._l((_vm.availableCourses),function(course){return _c('a-select-option',{key:course.key},[_vm._v(_vm._s(course.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.sectionName'),"prop":"name","rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          max: 50,
          message: _vm.$t('tips.textLimit', { num: 50 }),
        },
        {
          validator: _vm.validateName,
        },
        {
          whitespace: true,
          message: _vm.$t('common.enter'),
        } ]}},[_c('a-input',{attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(_vm.planForm.name),callback:function ($$v) {_vm.$set(_vm.planForm, "name", $$v)},expression:"planForm.name"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('common.teacher'),"required":true,"help":_vm.teacherError ? _vm.$t('course.selectTeacher') : '',"validateStatus":_vm.teacherError ? 'error' : ''}},[_c('a-form-model-item',{attrs:{"colon":false,"label":''}},[_c('a-radio-group',{on:{"change":_vm.onTeacherTypeChange},model:{value:(_vm.planForm.teacherType),callback:function ($$v) {_vm.$set(_vm.planForm, "teacherType", $$v)},expression:"planForm.teacherType"}},[_c('a-radio',{attrs:{"value":"teacher"}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.selectTeacher')))]),_c('a-radio',{attrs:{"value":"teacherGroup"}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.selectTeacherGroup')))])],1)],1),_c('a-form-model-item',{staticClass:"form-item-no-margin",attrs:{"colon":false,"label":''}},[_c('a-select',{attrs:{"showSearch":"","mode":_vm.planForm.teacherType === 'teacher' ? 'multiple' : 'default',"optionFilterProp":"children","placeholder":_vm.$t('common.select')},on:{"change":_vm.onTeacherChange},model:{value:(_vm.selectedTeacher),callback:function ($$v) {_vm.selectedTeacher=$$v},expression:"selectedTeacher"}},_vm._l((_vm.teacher4type),function(teacher){return _c('a-select-option',{key:teacher.key},[_vm._v(_vm._s(teacher.value))])}),1)],1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.weeklyClass'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.validateWeek,
        },
        {
          validator: _vm.validateWeek,
        } ],"prop":"hoursByWeek"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":50,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours', 'continuousTimes'])}},model:{value:(_vm.planForm.hoursByWeek),callback:function ($$v) {_vm.$set(_vm.planForm, "hoursByWeek", $$v)},expression:"planForm.hoursByWeek"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.consequentPeriods'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.validatePeriods,
          with: 'hoursByWeek',
        },
        {
          validator: _vm.validatePeriods,
          with: 'maxHoursByDay',
        } ],"prop":"continuousHours"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":20,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours', 'hoursByWeek', 'maxHoursByDay'])}},model:{value:(_vm.planForm.continuousHours),callback:function ($$v) {_vm.$set(_vm.planForm, "continuousHours", $$v)},expression:"planForm.continuousHours"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.consequentTimes'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.validateTimes,
        } ],"prop":"continuousTimes"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":0,"max":20,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours', 'hoursByWeek'])}},model:{value:(_vm.planForm.continuousTimes),callback:function ($$v) {_vm.$set(_vm.planForm, "continuousTimes", $$v)},expression:"planForm.continuousTimes"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.maxClasses'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        },
        {
          validator: _vm.valiedateMaxClasses,
        } ],"prop":"maxHoursByDay"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":20,"precision":0,"placeholder":_vm.$t('common.enter')},on:{"change":function($event){return _vm.onNumberChange(['continuousHours'])}},model:{value:(_vm.planForm.maxHoursByDay),callback:function ($$v) {_vm.$set(_vm.planForm, "maxHoursByDay", $$v)},expression:"planForm.maxHoursByDay"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('course.classroom'),"prop":"classroom"}},[_c('a-select',{attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('tips.selectClassroom'),"allowClear":""},model:{value:(_vm.planForm.classroom),callback:function ($$v) {_vm.$set(_vm.planForm, "classroom", $$v)},expression:"planForm.classroom"}},_vm._l((_vm.classrooms),function(classroom){return _c('a-select-option',{key:classroom.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? classroom.value : classroom.enValue || classroom.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.priorityTime'),"prop":"apm"}},[_c('a-select',{attrs:{"optionFilterProp":"children","placeholder":_vm.$t('common.select'),"allowClear":""},model:{value:(_vm.planForm.apm),callback:function ($$v) {_vm.$set(_vm.planForm, "apm", $$v)},expression:"planForm.apm"}},_vm._l((_vm.periods),function(period){return _c('a-select-option',{key:period.key},[_vm._v(_vm._s(period.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('smartCurriculum.priorityScheduling'),"rules":[
        {
          required: true,
          message: _vm.$t('common.enter'),
        } ],"prop":"priority"}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1,"max":100,"precision":0,"placeholder":_vm.$t('common.enter')},model:{value:(_vm.planForm.priority),callback:function ($$v) {_vm.$set(_vm.planForm, "priority", $$v)},expression:"planForm.priority"}}),_c('a-tooltip',{attrs:{"title":_vm.$t('smartCurriculum.priorityTips')}},[_c('a-icon',{staticStyle:{"vertical-align":"middle","margin-left":"4px"},attrs:{"type":"question-circle"}})],1)],1)],1),_c('transfer',{attrs:{"title":_vm.$t('course.addTeacher'),"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.transferVis),callback:function ($$v) {_vm.transferVis=$$v},expression:"transferVis"}}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.savePlan()}}},[_vm._v(_vm._s(_vm.$t('common.save')))]),(_vm.type === 'add')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.savePlan(true)}}},[_vm._v(_vm._s(_vm.$t('CCAClass.saveAndAdd')))]):_vm._e(),_c('a-button',{on:{"click":_vm.beforeClose}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }