var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed-schedule"},[_c('div',{staticClass:"fixed-schedule-container"},[_c('div',{staticClass:"course-list"},[_c('a-table',{staticStyle:{"margin-bottom":"16px"},attrs:{"data-source":_vm.courses,"columns":_vm.courseColumns,"pagination":false,"loading":_vm.loading,"row-key":function (record) { return record.uscCourseId; }},scopedSlots:_vm._u([{key:"name",fn:function(record){return [_c('draggable',{attrs:{"data-id":record.uscCourseId,"group":{
              name: 'timetable',
              pull: record.remainingPeriods > 0 ? 'clone' : false,
              put: false,
            },"list":[record],"sort":false,"move":_vm.onMove},on:{"end":_vm.courseOnEnd}},[_c('div',{staticClass:"course-item",style:({ backgroundColor: record.colour }),attrs:{"data-type":"course","data-id":record.uscCourseId}},[_vm._v(" "+_vm._s(record.name)+" ")])])]}},{key:"remainingPeriods",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$t('smartCurriculum.periods', record.remainingPeriods, { periods: record.remainingPeriods, }))+" ")]}}])},[_c('div',{staticClass:"title-text",attrs:{"slot":"customTitle"},slot:"customTitle"},[_vm._v(_vm._s(_vm.$t('smartCurriculum.courseClass')))])]),_c('a-table',{attrs:{"data-source":_vm.blocks,"columns":_vm.blockColumns,"pagination":false,"loading":_vm.loading,"row-key":function (record) { return record.block; }},scopedSlots:_vm._u([{key:"name",fn:function(record){return [_c('draggable',{attrs:{"data-block":record.block,"group":{
              name: 'timetable',
              pull: record.remainingPeriods > 0 ? 'clone' : false,
              put: false,
            },"list":[record],"sort":false,"move":_vm.onMove},on:{"end":_vm.blockOnEnd}},[_c('div',{staticClass:"block-item",attrs:{"data-type":"block","data-block":record.block}},[_c('div',{staticClass:"block-title",attrs:{"title":record.block}},[_vm._v(" "+_vm._s(record.block)+" ")]),_vm._l((record.courses),function(course){return _c('div',{key:("course-" + (course.uscCourseId)),staticClass:"course-item",style:({ backgroundColor: course.colour }),attrs:{"title":course.name}},[_c('span',[_vm._v(_vm._s(course.name))])])})],2)])]}},{key:"remainingPeriods",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$t('smartCurriculum.periods', record.remainingPeriods, { periods: record.remainingPeriods, }))+" ")]}}])},[_c('div',{staticClass:"title-text",attrs:{"slot":"customTitle"},slot:"customTitle"},[_vm._v("Block")])])],1),_c('div',{staticClass:"curriculum-container"},[_c('table',{staticClass:"weekly-calendar"},[_c('tr',{staticClass:"calendar-header"},[_c('td',{staticStyle:{"width":"60px"}}),_vm._l((5),function(weekday){return _c('td',{key:("weekday-" + weekday)},[_vm._v(_vm._s(_vm.$t(("weekday." + weekday))))])})],2),_vm._l((_vm.filteredClassPeriods),function(period){return _c('tr',{key:period.classPeriodId,staticClass:"calendar-row"},[_c('td',{staticClass:"calendar-title-cell",attrs:{"title":period.description}},[_vm._v(" "+_vm._s(period.description)+" ")]),_vm._l((5),function(weekday){return _c('td',{key:("weekday-" + (period.classPeriodId) + "-" + weekday),staticClass:"weekday-cell",class:{
              active: _vm.timetable[weekday][period.classPeriodId] === 1,
              disabled: _vm.timetable[weekday][period.classPeriodId] === 2,
            }},[(!Array.isArray(_vm.timetable[weekday][period.classPeriodId]))?[(_vm.timetable[weekday][period.classPeriodId] === 1)?_c('span',[_vm._v(_vm._s(_vm.$t('smartCurriculum.noClassTime')))]):_vm._e(),(_vm.timetable[weekday][period.classPeriodId] === 2)?_c('span',[_vm._v(_vm._s(_vm.$t('smartCurriculum.classPeriodOccupiedTime')))]):_vm._e()]:_c('draggable',{staticClass:"curriculum-item",attrs:{"list":_vm.timetable[weekday][period.classPeriodId],"group":{
                name: 'timetable',
                pull: true,
                put: true,
              },"sort":false,"move":_vm.onMove}},[_vm._l((_vm.timetable[weekday][period.classPeriodId]),function(item){return [(item.type === 'course')?_c('div',{key:item.uscCourseId,staticClass:"course-item",style:({ backgroundColor: item.colour }),attrs:{"data-type":"course","data-id":item.uscCourseId}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('a-button',{staticClass:"delete-button",staticStyle:{"color":"rgba(0, 0, 0, 0.65)"},attrs:{"type":"link","size":"small","icon":"close-circle"},on:{"click":function($event){return _vm.deleteCourse(weekday, period.classPeriodId, item.uscCourseId)}}})],1):_c('div',{key:item.block,staticClass:"block-item",attrs:{"data-type":"block","data-block":item.block}},[_c('div',{staticClass:"block-title",attrs:{"title":item.block}},[_c('span',[_vm._v(_vm._s(item.block))]),_c('a-button',{staticClass:"delete-button",staticStyle:{"color":"rgba(0, 0, 0, 0.45)"},attrs:{"type":"link","size":"small","icon":"delete"},on:{"click":function($event){return _vm.deleteBlock(weekday, period.classPeriodId, item.block)}}})],1),_vm._l((item.courses),function(course){return _c('div',{key:("course-" + (course.uscCourseId)),staticClass:"course-item",style:({ backgroundColor: course.colour }),attrs:{"title":course.name}},[_c('span',[_vm._v(_vm._s(course.name))])])})],2)]})],2)],2)})],2)})],2)])]),_c('div',{staticStyle:{"width":"100%","margin":"16px auto 0","text-align":"center"}},[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function (e) { return _vm.save(true); }}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.smartSchedule')))]),_c('a-button',{on:{"click":_vm.prev}},[_vm._v(_vm._s(_vm.$t('smartCurriculum.prev')))]),_c('a-button',{on:{"click":function (e) { return _vm.save(false); }}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }