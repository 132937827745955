var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"curriculum-component"},[_c('div',{staticClass:"info-container"},[_c('div',{staticClass:"curriculum-time"},[_c('a-button',{staticStyle:{"color":"#000"},attrs:{"type":"link","icon":"left"},on:{"click":_vm.prevWeek}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$d(_vm.startTime, 'short') + '-' + _vm.$d(_vm.endTime, 'short')))]),_c('a-button',{staticStyle:{"color":"#000"},attrs:{"type":"link","icon":"right"},on:{"click":_vm.nextWeek}})],1),_c('div',{staticClass:"curriculum-other"},[_c('i18n',{staticClass:"total",attrs:{"path":_vm.type.indexOf('teacher') === -1
            ? 'adjustCurriculum.classroomTotal'
            : 'adjustCurriculum.teacherTotal',"tag":"span"},scopedSlots:_vm._u([{key:"total",fn:function(){return [_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.total))])]},proxy:true},{key:"substitute",fn:function(){return [_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.substitute))])]},proxy:true}])})],1)]),_c('div',{staticClass:"curriculum-table"},[_c('div',{staticClass:"curriculum-week-block"},[_c('div',{staticClass:"empty-block"}),_vm._l((_vm.week),function(day){return _c('div',{key:day.dayOfWeek,staticClass:"curriculum-row"},[_c('div',{staticStyle:{"width":"44px"}},[_c('div',{staticClass:"day-title"},[_c('span',{staticClass:"en-title"},[_vm._v(_vm._s(day.enName))]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(day.name))])])])])})],2),_c('div',{staticClass:"curriculum-table-body"},[_c('div',{staticClass:"period-row"},[_vm._l((_vm.periods),function(period){return _c('div',{key:period.classPeriodId,staticClass:"period-item"},[_c('span',[_vm._v(_vm._s(_vm.moment(period.start) .tz('Asia/Shanghai') .format('HH:mm')))])])}),_c('div',{staticClass:"period-item-last"}),_c('div',{staticClass:"fill-block"})],2),_c('div',{staticClass:"curriculum-class-block"},_vm._l((_vm.week),function(day){return _c('div',{key:day.dayOfWeek,staticClass:"curriculum-row"},[_vm._l((_vm.periods),function(period){return _c('div',{key:period.classPeriodId,staticClass:"course-container"})}),_c('div',{staticClass:"fill-block"}),_vm._l((_vm.courses[day.dayOfWeek]),function(course,index){return _c('div',{key:course.classArrangeId,staticClass:"course-item-outer",style:({
              left: _vm.calcLeft(course.startTime),
              width: _vm.calcWidth(course.startTime, course.endTime),
              zIndex: index,
              height: course.crossHeight,
              top: course.crossTop,
            }),on:{"mouseover":function($event){course.crossGroupLength > 1 ? (course.showAll = true) : ''},"mouseleave":function($event){course.crossGroupLength > 1 ? (course.showAll = false) : ''}}},[_c('div',{staticClass:"course-item",style:({
                width: _vm.calcWidth(course.startTime, course.endTime),
                backgroundColor:
                  course.type === '1001'
                    ? _vm.calcBg(course.courseId.subject.color)
                    : 'rgba(70,194,154,0.2)',
              })},[(_vm.calcSubstitute(course.teachers) && _vm.type !== 'classroom')?_c('div',{staticClass:"substitute-badge"},[_vm._v(" 代 "),_c('div',{staticClass:"bottom"})]):_vm._e(),_c('div',{staticClass:"side-bar",style:({
                  backgroundColor:
                    course.type === '1001' ? course.courseId.subject.color : 'transparent',
                })}),_c('div',{staticClass:"course-content",class:{ 'primary-color-font': course.type !== '1001' }},[(course.type === '1002')?_c('div',{staticStyle:{"overflow":"hidden","transition":".2s all ease-in-out","flex-shrink":"0"},style:({ height: course.showAll ? '21px' : '0px' })},[_vm._v(" Life Block ")]):_vm._e(),_c('div',{staticStyle:{"overflow":"hidden","transition":".2s all ease-in-out","flex-shrink":"0"},style:({ height: course.showAll || course.type === '1002' ? '21px' : '0px' }),attrs:{"title":_vm.moment(course.startTime)
                      .tz('Asia/Shanghai')
                      .format('HH:mm') +
                      '-' +
                      _vm.moment(course.endTime)
                        .tz('Asia/Shanghai')
                        .format('HH:mm')}},[_vm._v(" "+_vm._s(_vm.moment(course.startTime) .tz('Asia/Shanghai') .format('HH:mm') + '-' + _vm.moment(course.endTime) .tz('Asia/Shanghai') .format('HH:mm'))+" ")]),(_vm.type !== 'classroom' || _vm.type === 'student')?_c('span',{attrs:{"title":(course.classRoomId || {}).code}},[_vm._v(_vm._s((course.classRoomId || {}).code))]):_vm._e(),(_vm.type === 'classroom' || _vm.type === 'student')?_c('span',{attrs:{"title":_vm.getTeachersName(course)}},[_vm._v(_vm._s(_vm.getTeachersName(course)))]):_vm._e(),(course.courseId.subject)?_c('span',{attrs:{"title":course.courseId.subject.description}},[_vm._v(_vm._s(course.courseId.subject.description))]):_vm._e(),(course.courseId.description)?_c('span',{attrs:{"title":course.courseId.description}},[_vm._v(_vm._s(course.courseId.description))]):_vm._e()])])])})],2)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }