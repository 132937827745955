var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"i-c-section"},[_c('div',{staticClass:"tab-menu"},[_c('a-menu',{staticClass:"menu",attrs:{"mode":"inline","selectedKeys":_vm.menuKey},on:{"click":_vm.changeMenu}},_vm._l((_vm.tabs),function(tab){return _c('a-menu-item',{key:tab.key,attrs:{"title":tab.value}},[_vm._v(_vm._s(tab.value))])}),1)],1),_c('div',{staticClass:"plan-content"},[_c('div',{staticClass:"button-container"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.add')))])])],1),_c('a-table',{staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"dataSource":_vm.section4Type,"pagination":false,"rowKey":function (record, index) { return record.id; }},scopedSlots:_vm._u([{key:"commonFlex",fn:function(text){return [_c('flex-tooltip',{attrs:{"value":text.value}})]}},{key:"mutexSections",fn:function(text){return [_c('flex-tooltip',{attrs:{"value":text.map(function (item) { return item.value; }).join('、')}})]}},{key:"operations",fn:function(record){return [_c('a',{on:{"click":function($event){return _vm.edit(record)}}},[_vm._v(_vm._s(_vm.$t('common.edit')))]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"title":_vm.$t('smartCurriculum.planDeleteConfirm')},on:{"confirm":function($event){return _vm.deleteRecord(record.id)}}},[_c('a',[_vm._v(_vm._s(_vm.$t('common.delete')))])])]}}])},[_vm._v("mutexSections ")])],1),_c('a-modal',{attrs:{"visible":_vm.modalVis,"title":((_vm.$t(("common." + _vm.editType))) + " " + (_vm.$t(
        ("smartCurriculum." + (_vm.menuKey[0] === 'mutex' ? 'in' : '') + "compatibleClass")
      )) + " Section"),"width":600,"okText":_vm.$t('common.save'),"maskClosable":false,"destroyOnClose":""},on:{"cancel":_vm.beforeClose}},[_c('a-form-model',{ref:"sectionForm",staticClass:"section-form",attrs:{"model":_vm.sectionForm}},[_c('a-form-model-item',{attrs:{"label":"Section","rules":[
          {
            required: true,
            message: _vm.$t('common.select'),
          } ],"prop":"sectionId"}},[_c('a-cascader',{staticClass:"filter-input",attrs:{"options":_vm.sections,"showSearch":{ filter: _vm.filterData },"fieldNames":{
            label: _vm.locale === 'zh' ? 'value' : 'enValue',
            value: 'key',
            children: 'subOptions',
          },"placeholder":_vm.$t('common.select'),"allowClear":true,"disabled":_vm.editType === 'edit'},on:{"change":_vm.onSectionChange},model:{value:(_vm.sectionForm.sectionId),callback:function ($$v) {_vm.$set(_vm.sectionForm, "sectionId", $$v)},expression:"sectionForm.sectionId"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t(("smartCurriculum." + (_vm.menuKey[0] === 'mutex' ? 'in' : '') + "compatibleSection")),"required":true,"help":_vm.sectionError ? _vm.$t('common.select') : '',"validateStatus":_vm.sectionError ? 'error' : ''}},[((_vm.sectionForm.mutexSections || []).length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.sectionForm.mutexSections),function(item){return _c('a-tag',{key:item.id,attrs:{"visible":"","closable":""},on:{"close":function($event){return _vm.removeItem(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus"},on:{"click":function($event){return _vm.addSection()}}},[_vm._v(_vm._s(_vm.$t('common.addSection')))])],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveSection()}}},[_vm._v(_vm._s(_vm.$t('common.save')))]),(_vm.editType === 'add')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveSection(true)}}},[_vm._v(_vm._s(_vm.$t('CCAClass.saveAndAdd')))]):_vm._e(),_c('a-button',{on:{"click":_vm.beforeClose}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1),_c('group-transfer',{attrs:{"title":_vm.$t('common.section'),"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.groupTransferVis),callback:function ($$v) {_vm.groupTransferVis=$$v},expression:"groupTransferVis"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }